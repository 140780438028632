// const axios = require('axios');
import axios from 'axios'
import { history } from '../history'

const instance = axios.create({
  baseURL: 'https://api.bankminer.com.br/',
  // baseURL: 'http://localhost:3001/api',
  withCredentials: true
})

instance.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response.status === 401) {
      history.push('/plataforma/login')
    }
    return Promise.reject(error.response)
  }
)

// module.exports = instance;
export default instance
