import React from 'react'
import * as Icon from 'react-feather'

const navigationConfig = [
  {
    id: 'admHome',
    title: 'Home',
    type: 'item',
    icon: <Icon.Home size={20} />,
    permissions: ['admin'],
    navLink: '/plataforma/admin/home'
  },
  {
    id: 'home',
    title: 'Início',
    type: 'item',
    icon: <Icon.Home size={20} />,
    permissions: ['customer'],
    navLink: '/plataforma/home'
  },
  {
    id: 'activity',
    title: 'Atividade',
    type: 'item',
    icon: <Icon.AlignLeft size={20} />,
    permissions: ['customer'],
    navLink: '/plataforma/atividade'
  },
  {
    id: 'deposit',
    title: 'Depositar',
    type: 'item',
    icon: <Icon.Download size={20} />,
    permissions: ['customer'],
    navLink: '/plataforma/depositar'
  },
  {
    id: 'exchange',
    title: 'Converter',
    type: 'item',
    icon: <Icon.Repeat size={20} />,
    permissions: ['customer'],
    navLink: '/plataforma/converter'
  },
  // {
  //   id: 'buyTrhs',
  //   title: 'Comprar TRHS',
  //   type: 'item',
  //   icon: <Icon.Repeat size={20} />,
  //   permissions: ['customer'],
  //   navLink: '/plataforma/comprar-trhs'
  // },
  // {
  //   id: 'thrsDashboard',
  //   title: 'Painel TRHS',
  //   type: 'item',
  //   icon: <Icon.Trello size={20} />,
  //   permissions: ['customer'],
  //   navLink: '/plataforma/dashboard-trhs'
  // },
  {
    id: 'withdraw',
    title: 'Saque',
    type: 'collapse',
    icon: <Icon.Upload size={20} />,
    children: [
      {
        id: 'withdraw-self',
        title: 'Conta Bancária',
        type: 'item',
        icon: <Icon.User size={20} />,
        permissions: ['customer'],
        navLink: '/plataforma/sacar'
      },
      {
        id: 'withdraw-wallet',
        title: 'Carteira',
        type: 'item',
        icon: <Icon.CreditCard size={20} />,
        permissions: ['customer'],
        navLink: '/plataforma/sacar-wallet'
      }
      // {
      //   id: 'withdraw-admin',
      //   title: 'Gerenciar',
      //   type: 'item',
      //   icon: <Icon.List size={20} />,
      //   permissions: ['admin'],
      //   navLink: '/plataforma/admin/saques'
      // }
    ]
  },
  {
    id: 'bankAccounts',
    title: 'Contas Bancárias',
    type: 'item',
    icon: <Icon.Book size={20} />,
    permissions: ['customer'],
    navLink: '/plataforma/contas-bancarias'
  },
  // {
  //   id: "settings",
  //   title: "Configurações",
  //   type: "item",
  //   icon: <Icon.Settings size={20} />,
  //   permissions: ["customer"],
  //   navLink: "/configuracoes"
  // },
  {
    id: 'home',
    title: 'Início',
    type: 'item',
    icon: <Icon.Home size={20} />,
    permissions: ['client'],
    navLink: '/plataforma/clients/home'
  },
  {
    id: 'reports',
    title: 'Métricas',
    type: 'item',
    icon: <Icon.BarChart2 size={20} />,
    permissions: ['client'],
    navLink: '/plataforma/clients/metrics'
  },
  {
    id: 'payments',
    title: 'Pagamentos',
    type: 'item',
    icon: <Icon.DollarSign size={20} />,
    permissions: ['client'],
    navLink: '/plataforma/clients/payments'
  },
  {
    id: 'withdraw',
    title: 'Saques',
    type: 'item',
    icon: <Icon.Download size={20} />,
    permissions: ['client'],
    navLink: '/plataforma/clients/withdraws'
  },
  {
    id: 'import',
    title: 'Importar',
    type: 'item',
    icon: <Icon.FilePlus size={20} />,
    permissions: ['client'],
    navLink: '/plataforma/clients/import'
  },
  {
    id: 'export',
    title: 'Exportar',
    type: 'item',
    icon: <Icon.LogOut size={20} />,
    permissions: ['client'],
    navLink: '/plataforma/clients/export'
  },
  // {
  //   id: 'support',
  //   title: 'Support',
  //   type: 'item',
  //   icon: <Icon.MessageCircle size={20} />,
  //   permissions: ['client'],
  //   navLink: '/clients/support',
  // },

  // {
  //   id: 'admMiningRequests',
  //   title: 'Mineração',
  //   type: 'item',
  //   icon: <Icon.BarChart2 size={20} />,
  //   permissions: ['admin'],
  //   navLink: '/plataforma/admin/mining-requests'
  // },
  // {
  //   id: "clientDeposits",
  //   title: "Depositos",
  //   type: "item",
  //   icon: <Icon.DownloadCloud />,
  //   //permissions: [""],
  //   navLink: "/plataforma/client/deposits",
  // },
  {
    id: 'admInvites',
    title: 'Convites',
    type: 'item',
    icon: <Icon.Gift size={20} />,
    permissions: ['admin'],
    navLink: '/plataforma/admin/invites'
  }
]

export { navigationConfig }
