import React from 'react'
import { NavLink } from 'react-router-dom'
import { Disc, X, Circle } from 'react-feather'
import classnames from 'classnames'
import { connect } from 'react-redux'
// import LogoPiggy from 'assets/piggy/svg/LOGO PIGGY - 1.svg'
import logoIcon from '../../../../assets/img/logo/logo-pay42-icon.png'
import logoText from '../../../../assets/img/logo/logo-pay42-text.png'

const SidebarHeader = ({
  toggleSidebarMenu,
  activeTheme,
  collapsed,
  toggle,
  sidebarVisibility,
  menuShadow,
  user
}) => {
  return (
    <div className="navbar-header">
      <ul className="nav navbar-nav flex-row">
        <li className="nav-item mr-auto d-flex justify-content-center">
          <NavLink
            to={`/plataforma/${
              user.userRole === 'customer'
                ? ''
                : user.userRole === 'client'
                ? 'clients/'
                : user.userRole === 'admin'
                ? 'admin/'
                : ''
            }home/`}
            className="navbar-brand"
            style={{ marginTop: 0 }}
          >
            {/* <img
              src={LogoPiggy}
              alt=""
              style={{ maxHeight: '70px', marginLeft: '-20px', marginRight: '10px' }}
            /> */}
            <span style={{ fontSize: '1.65rem', fontWeight: 'bold' }}>
              Piggy Bank
            </span>
          </NavLink>
        </li>
        <li className="nav-item nav-toggle">
          <div className="nav-link modern-nav-toggle">
            {collapsed === false ? (
              <Disc
                onClick={() => {
                  toggleSidebarMenu(true)
                  toggle()
                }}
                className={classnames(
                  'toggle-icon icon-x d-none d-xl-block font-medium-4',
                  {
                    'text-primary': activeTheme === 'primary',
                    'text-success': activeTheme === 'success',
                    'text-danger': activeTheme === 'danger',
                    'text-info': activeTheme === 'info',
                    'text-warning': activeTheme === 'warning',
                    'text-dark': activeTheme === 'dark'
                  }
                )}
                size={20}
                data-tour="toggle-icon"
                style={{ marginTop: '3px' }}
              />
            ) : (
              <Circle
                onClick={() => {
                  toggleSidebarMenu(false)
                  toggle()
                }}
                className={classnames(
                  'toggle-icon icon-x d-none d-xl-block font-medium-4',
                  {
                    'text-primary': activeTheme === 'primary',
                    'text-success': activeTheme === 'success',
                    'text-danger': activeTheme === 'danger',
                    'text-info': activeTheme === 'info',
                    'text-warning': activeTheme === 'warning',
                    'text-dark': activeTheme === 'dark'
                  }
                )}
                size={20}
              />
            )}
            <X
              onClick={sidebarVisibility}
              className={classnames(
                'toggle-icon icon-x d-block d-xl-none font-medium-4',
                {
                  'text-primary': activeTheme === 'primary',
                  'text-success': activeTheme === 'success',
                  'text-danger': activeTheme === 'danger',
                  'text-info': activeTheme === 'info',
                  'text-warning': activeTheme === 'warning',
                  'text-dark': activeTheme === 'dark'
                }
              )}
              size={20}
            />
          </div>
        </li>
      </ul>
      <div
        className={classnames('shadow-bottom', {
          'd-none': menuShadow === false
        })}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return { user: state.auth.user }
}

export default connect(mapStateToProps)(SidebarHeader)
