import React, { useState, useEffect } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import moment from 'moment'
import 'moment/locale/pt-br'

import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge
} from 'reactstrap'

import { Bell, MinusCircle, PlusCircle, CheckCircle } from 'react-feather'

import useInterval from 'hooks/useInterval'

import api from 'services/api'

export default function NavbarNotifications() {
  const [notifications, setNotifications] = useState([])

  const getNotifications = async () => {
    try {
      const respApi = await api.get('/notifications')
      const { data } = respApi
      setNotifications(data)
    } catch (error) {
      console.log(error)
    }
  }

  const readAllNotifications = async () => {
    try {
      await api.post('/notifications')
      await getNotifications()
    } catch (error) {
      console.log(error)
    }
  }

  const renderPlaceholder = () => {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: '100px' }}
      >
        <CheckCircle size={21} className="mr-1" />
        <b>Nenhuma Notificação</b>
      </div>
    )
  }

  // useInterval(getNotifications, 60000)

  // useEffect(() => {
  //   // getNotifications();
  // }, [])

  return (
    <>
      <UncontrolledDropdown tag="li" className="dropdown-notification nav-item">
        <DropdownToggle tag="a" className="nav-link nav-link-label">
          <Bell size={21} />
          {notifications.length > 0 && (
            <Badge pill color="primary" className="badge-up">
              {notifications.length}{' '}
            </Badge>
          )}
        </DropdownToggle>
        <DropdownMenu tag="ul" right className="dropdown-menu-media">
          <li className="dropdown-menu-header">
            <div className="dropdown-header mt-0">
              <b className="text-white">Notificações</b>
              {/* <span className="notification-title">Não lidas</span> */}
            </div>
          </li>
          {notifications.length > 0 ? (
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false
              }}
            >
              {notifications.map(item => {
                return (
                  <div className="d-flex justify-content-between">
                    <Media className="d-flex align-items-start">
                      <Media left href="#">
                        {item.status === 0 ? (
                          <MinusCircle
                            className="font-medium-5 text-danger"
                            size={21}
                          />
                        ) : (
                          <PlusCircle
                            className="font-medium-5 text-success"
                            size={21}
                          />
                        )}
                      </Media>
                      <Media body>
                        <Media
                          heading
                          className="primary media-heading"
                          tag="h6"
                        >
                          {item.title}
                        </Media>
                        <p className="notification-text">{item.content}</p>
                      </Media>
                      <small>
                        <time
                          className="media-meta"
                          dateTime="2015-06-11T18:29:20+08:00"
                        >
                          {moment.unix(item.created_at).fromNow()}
                        </time>
                      </small>
                    </Media>
                  </div>
                )
              })}
            </PerfectScrollbar>
          ) : (
            renderPlaceholder()
          )}

          {notifications.length > 0 && (
            <li className="dropdown-menu-footer">
              <DropdownItem
                tag="a"
                className="p-1 text-center"
                onClick={readAllNotifications}
              >
                <span className="align-middle">Marcar todas como lidas</span>
              </DropdownItem>
            </li>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  )
}
