const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (e) {
    // Ignore write errors;
  }
};

export const login = (state = { userRole: 'admin' }, action) => {
  switch (action.type) {
    case 'CHANGE_ROLE': {
      console.log(state);
      console.log(action);
      saveState({ ...action.user });
      return { ...action.user };
    }
    default: {
      const respLoad = loadState();
      if (respLoad) return respLoad;
      return state;
    }
  }
};
